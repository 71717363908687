.our-advantages-component {
  width: 1440px;
  margin: 0 auto;
  padding: 0 0 120px 0;

  .main-title {
    margin: 64px 0;
  }

  .group-elements {
    display: flex;
    flex-direction: column;
    gap: 64px;
  }

  .content {
    display: flex;
    color: white;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #e7fef5;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .content-info {
    width: 70%;
  }
  .content-title {
    font-family: "Kinetika-SemiBold", sans-serif;
    font-size: 32px;
    line-height: 40px;
  }
  .content-description {
    font-family: "Kinetika-Regular", sans-serif;
    font-size: 20px;
    line-height: 25px;
    margin-top: 8px;
  }
  .content-number {
    font-family: "Medium_Cyrillic", sans-serif;
    font-weight: 500;
    font-size: 80px;
    line-height: 72px;
  }
}

@media (max-width: 1440px) {
  .our-advantages-component {
    width: 100%;
    margin: 0 auto;
    padding: 0 32px 120px;

    .main-title {
      margin: 64px 0;
    }

    .content {
      display: flex;
      color: white;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 24px;
      border-bottom: 1px solid #e7fef5;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .content-info {
      width: 70%;
    }
    .content-title {
      font-family: "Kinetika-SemiBold", sans-serif;
      font-size: 32px;
      line-height: 40px;
    }
    .content-description {
      font-family: "Kinetika-Regular", sans-serif;
      font-size: 20px;
      line-height: 25px;
      margin-top: 8px;
    }
    .content-number {
      font-family: "Medium_Cyrillic", sans-serif;
      font-weight: 500;
      font-size: 80px;
      line-height: 72px;
    }
  }
}

@media (max-width: 768px) {
  .our-advantages-component {
    padding: 0 16px;
    width: 100%;

    .content {
      padding-bottom: 20px;
      margin-bottom: 24px;
      gap: 20px;
    }

    .group-elements {
      gap: 24px;
    }

    .content-info {
      width: 100%;
    }
    .content-title {
      font-size: 18px;
      line-height: 25px;
    }
    .content-description {
      font-size: 14px;
      line-height: 20px;
    }
    .content-number {
      font-size: 40px;
      line-height: 50px;
    }

    .main-title {
      margin: 0 0 16px 0;
    }
  }
}
