@import "src/globall";

.footer-component {
  background: #005235;
  border-top-left-radius: 64px;
  border-top-right-radius: 64px;

  .footer-content {
    width: 1440px;
    padding: 64px 0 64px 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  .footer-bottom {
    width: 1440px;
    margin: 0 auto;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e7fef5;

    .comp-info {
      display: flex;
      flex-direction: column;
      gap: 8px;

      br {
        display: none;
      }
    }

    p {
      color: white;
      font-size: 18px;
      line-height: 22px;
      font-family: "Kinetika-Regular", sans-serif;
      margin: 0;
    }
  }

  .info-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 40%;

    a {
      text-decoration: none;
    }

    .title {
      font-family: "Medium_Cyrillic", sans-serif;
      font-size: 24px;
      line-height: 44px;
      color: white;
    }
    .order-button {
      @include button-global;
      border: none;
      color: #005235;
      background: #fff;
      font-family: "Kinetika-SemiBold", sans-serif;
      padding: 16px 40px;
      width: max-content;
    }
  }

  .footer-links {
    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 0;
      padding: 0;

      li {
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-family: "Kinetika-Regular", sans-serif;

        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  .contacts {
    color: white;

    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin: 0;
      padding: 0;

      li {
        color: white;
        font-size: 18px;
        line-height: 22px;
        font-family: "Kinetika-Regular", sans-serif;

        a {
          color: white;
          text-decoration: none;
        }
      }
    }
  }
  .social-section {
    ul {
      list-style-type: none;
      display: flex;
      gap: 16px;
      margin: 0;
      padding: 0;

      li {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .footer-component {
    .footer-content {
      width: 100%;
      padding: 40px 32px;
    }

    .footer-bottom {
      width: 100%;
      padding: 24px 32px;
    }

    .info-section {
      gap: 18px;
      width: 50%;

      .title {
        font-size: 22px;
        line-height: 40px;
      }
      .order-button {
        padding: 4px 40px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .footer-component {
    .footer-bottom {
      padding-bottom: 80px;
      align-items: start;
    }
  }
}

@media screen and (max-width: 768px) {
  .footer-component {
    margin-top: 64px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    .footer-content {
      padding: 32px 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      // flex-direction: column;
      flex-wrap: wrap;
      gap: 30px;
    }
    .info-section {
      gap: 16px;
      width: 100%;

      .title {
        font-size: 20px;
        line-height: 34px;
      }
      .order-button {
        padding: 6px 30px;
        display: none;
      }
    }
    .social-section {
      ul {
        gap: 8px;

        li {
          img {
            width: 30px;
            height: 30px;
            object-fit: cover;
          }
        }
      }
    }

    .footer-container {
      margin-inline: 16px;
    }

    .footer-bottom {
      padding-inline: 0;
      width: 100%;
      gap: 10px;

      .comp-info {
        display: flex;
        flex-direction: column;
        gap: 0;

        br {
          display: block;
        }
      }

      p {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}
