.news-component {
  padding: 174px 0 120px 0;
  margin: 0 auto;
  width: 1440px;

  .content-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
    padding-block: 20px;
    padding-inline: 10px;

    .box {
      height: 280px;
      box-sizing: border-box;
      border-radius: 16px;
      overflow: hidden;
      transition: 0.5s linear;
      position: relative;

      .text {
        padding: 16px;
        position: absolute;
        z-index: 1;
        bottom: 0;
        font-size: 18px;
        line-height: 20px;
        font-family: "Kinetika-Medium", sans-serif;
        color: white;
      }
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    font-size: 18px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform-origin: "center center";
  }

  .swiper-slide .linear {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      0deg,
      rgba(48, 48, 48, 0.8) 0%,
      rgba(24, 24, 24, 0.24) 33%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

@media screen and (max-width: 1440px) {
  .news-component {
    padding: 144px 32px 60px;
    width: 100%;

    .content-box {
      margin-top: 32px;
      padding-block: 10px;
      padding-inline: 10px;
    }
  }
}

@media screen and (max-width: 1236px) {
  .news-component {
    padding: 0 32px 60px;
  }
}

@media screen and (max-width: 768px) {
  .news-component {
    padding: 0px 16px;

    .content-box {
      margin-top: 16px;

      .box {
        height: 230px;
        box-sizing: border-box;
        border-radius: 16px;
      }
    }
  }
}
