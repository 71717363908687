$primary: #005235;
$accent: #00a000;
$line-color: #e7fef5;
$white: #ffffff;
$backgroun-color: #001200;
$layer-color: #00a76a;

@mixin button-global {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 22px;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;

  @media (max-width: 768px) {
    height: 36px;
    font-size: 14px;
    line-height: 20px;
    border-radius: 10px;
  }

  @media (max-width: 480px) {
    height: 32px;
    font-size: 13px;
    line-height: 18px;
    vertical-align: middle;
    border-radius: 8px;
  }
}

@mixin blured-bg {
  background: #ffffff1a;
  backdrop-filter: blur(12px);
}

@mixin blured-bg-modal {
  background: #ffffff1a;
  backdrop-filter: blur(20px);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  background-color: $backgroun-color;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  height: 20px;
  background: #fff;
}

body::-webkit-scrollbar-thumb {
  background: #005235;
}

@font-face {
  font-family: "Kinetika-Bold";
  src: url("./fonts/kinetika/Kinetika-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Kinetika-Medium";
  src: url("./fonts/kinetika/Kinetika-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Kinetika-Regular";
  src: url("./fonts/kinetika/Kinetika-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Kinetika-SemiBold";
  src: url("./fonts/kinetika/Kinetika-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Medium_Cyrillic";
  src: url("./fonts/medium/Medium_Cyrillic.ttf") format("truetype");
}
@font-face {
  font-family: "Medium";
  src: url("./fonts/medium/Medium.otf") format("opentype");
}

%globalButton {
  @include button-global;
  padding: 2px 40px 2px 40px;
  background: #005235;
  color: white;
  border: none;
  font-family: "Kinetika-Regular", sans-serif;
}

.main-title {
  display: flex;
  align-items: center;
  .line {
    background-color: #00a000;
    height: 4px;
    width: 103px;
  }
  .text {
    font-family: "Medium_Cyrillic", sans-serif;
    font-size: 40px;
    line-height: 71px;
    margin-left: 24px;
    color: white;
  }
}

.main-component {
  position: relative;
  width: 100%;
  height: 850px;
  overflow: hidden;
  video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 850px;
    z-index: -1;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
}

.preloader-loading {
  background-color: $primary;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.row-bg-overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: " ";
  z-index: 1;
  backface-visibility: hidden;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 252) 5%, rgba(0, 0, 0, 0) 55%);
}

.modal {
  background: rgba(0, 0, 0, 0.7); /* Yorug'lik darajasini boshqarish */
}

.order-modal {
  @include blured-bg-modal;
  max-width: 800px !important;
  height: 639px;
  border-radius: 12px;
  padding: 8px;

  .modal-content {
    background: transparent !important;
    box-shadow: none !important;
    border: none !important;
  }
  .modal-header {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 24px;
    background-color: transparent;
    border: none;
    padding: 16px 16px 0 16px;

    .modal-title {
      font-family: "Medium_Cyrillic", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 31px;
      color: white;
    }

    .btn-close {
      color: white;
      .ant-modal-close-x {
        font-size: 20px;
      }
    }
  }
  .modal-body {
    padding: 0 16px 16px 16px;
    border: none;
    label {
      font-family: "Kinetika-Regular", sans-serif;
      line-height: 20px;
      text-align: left;
      color: white;
    }

    input,
    textarea {
      width: 100%;
      height: 40px;
      background-color: transparent;
      border-radius: 8px;
      padding: 12px 24px;
      box-shadow: none !important;
      outline: none !important;
      border: 1px solid white;
      font-family: "Kinetika-Regular", sans-serif;

      color: white;
      &::placeholder {
        color: white;
      }
    }

    textarea {
      height: 132px;
    }

    .services-btn {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      gap: 10px;
    }

    .modal-btn {
      @include button-global;
    }

    .selected-item {
      padding: 12px 24px;
      font-family: "Kinetika-Regular", sans-serif;
      background: $primary;
      border: 1px solid $primary;
      color: white;
    }

    .default-item {
      padding: 12px 24px;
      border: 1px solid white;
      font-family: "Kinetika-Regular", sans-serif;
      color: white;
      background-color: transparent;
      &:hover {
        color: white;
        background: $primary;
        border: 1px solid $primary;
      }
    }

    .submit-button {
      @include button-global;
      width: 100%;
      margin-top: 8px;
      background: $accent;
      color: white;
      font-family: "Kinetika-Regular", sans-serif;
      font-size: 18px;
      border: none;
      box-shadow: none;
      outline: none !important;
      &:hover {
        background: $accent;
        opacity: 0.8;
        color: white;
      }
    }
  }
}

.text-flip-content {
  position: relative;
  overflow: hidden;

  .text,
  .hover-text {
    transition: transform 400ms cubic-bezier(0.16, 1, 0.3, 1);
    -webkit-transition: transform 400ms cubic-bezier(0.16, 1, 0.3, 1);
    -moz-transition: transform 400ms cubic-bezier(0.16, 1, 0.3, 1);
    -ms-transition: transform 400ms cubic-bezier(0.16, 1, 0.3, 1);
    -o-transition: transform 400ms cubic-bezier(0.16, 1, 0.3, 1);
  }

  .vertical .text,
  .vertical .hover-text {
    transform: translateY(0%);
  }

  .hover-text {
    position: absolute;
  }

  .vertical .hover-text {
    bottom: -100%;
  }
}
.text-flip:hover .vertical .hover-text,
.text-flip:hover .vertical .text {
  transform: translateY(-100%);
}

.animatedWord {
  column-gap: 20px;
}

@media screen and (max-width: 1236px) {
  .main-title {
    display: flex;
    align-items: center;
    .line {
      height: 2px;
      width: 80px;
    }
    span {
      font-size: 32px;
      line-height: 60px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .order-modal {
    width: 100%;
    height: auto;
    top: 30px;
  }

  .main-component {
    height: 100vh;
    video {
      height: 100vh;
    }
  }
}

@media screen and (max-width: 768px) {
  .main-title {
    display: flex;
    align-items: center;
    .line {
      background-color: $accent;
      height: 2px;
      width: 40px;
    }
    span {
      font-size: 22px;
      line-height: 40px;
    }
  }

  .order-modal {
    .modal-body {
      input,
      textarea {
        height: 32px;
        font-size: 14px;
        padding: 6px 18px;
      }

      .selected-item {
        padding: 6px 18px;
      }

      .default-item {
        padding: 6px 18px;
      }
    }
  }

  .modal {
    padding-inline: 20px !important;
  }

  .modal-dialog {
    margin-inline: 0;
  }

  .animatedWord {
    column-gap: 10px;
  }
}
