@import "src/globall";

.our-team-component {
  width: 1440px;
  margin: 0 auto;
  padding: 32px 8px 120px;

  .content-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding-bottom: 16px;

    .box {
      position: relative;
      // width: 25%;
      height: 380px;
      box-sizing: border-box;
      border-radius: 24px;
      display: flex;
      align-items: flex-end;
      background-size: cover;
      transition: 0.5s linear;
      &:hover {
        transform: scale(1.05);
      }
    }

    .box-img {
      width: 100%;
      height: 100%;
      position: absolute;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 0%;
        border-radius: 24px;
        -webkit-border-radius: 24px;
        -moz-border-radius: 24px;
        -ms-border-radius: 24px;
        -o-border-radius: 24px;
      }
    }

    .box-overlay-container {
      position: absolute;
      width: 100%;
      padding-inline: 16px;
      bottom: 16px;
    }

    .box-overlay {
      height: 55px;
      width: 100%;
      display: flex;
      border-radius: 16px;
      @include blured-bg;
      padding: 8px 8px 8px 16px;
      box-sizing: border-box;
      align-items: center;
      justify-content: space-around;
      gap: 8px;

      .name {
        font-family: "Kinetika-Regular", sans-serif;
        font-size: 18px;
        line-height: 22.5px;
        color: #fff;
      }

      .role {
        width: 145px;
        height: 40px;
        border-radius: 12px;
        background-color: #005235;
        color: white;
        font-family: "Kinetika-Regular", sans-serif;
        font-size: 18px;
        line-height: 22.5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .content-box1 {
    .box1 {
      transform: translateY(248px);
      -webkit-transform: translateY(248px);
      -moz-transform: translateY(248px);
      -ms-transform: translateY(248px);
      -o-transform: translateY(248px);

      &:hover {
        transform: translateY(248px) scale(1.05);
        -webkit-transform: translateY(248px) scale(1.05);
        -moz-transform: translateY(248px) scale(1.05);
        -ms-transform: translateY(248px) scale(1.05);
        -o-transform: translateY(248px) scale(1.05);
      }
    }
    .box2,
    .box4 {
      transform: translateY(128px);
      -webkit-transform: translateY(128px);
      -moz-transform: translateY(128px);
      -ms-transform: translateY(128px);
      -o-transform: translateY(128px);
      &:hover {
        transform: translateY(128px) scale(1.05);
        -webkit-transform: translateY(128px) scale(1.05);
        -moz-transform: translateY(128px) scale(1.05);
        -ms-transform: translateY(128px) scale(1.05);
        -o-transform: translateY(128px) scale(1.05);
      }
    }
  }

  .content-box2 {
    margin: 32px 0;

    .box1 {
      transform: translateY(248px);
      -webkit-transform: translateY(248px);
      -moz-transform: translateY(248px);
      -ms-transform: translateY(248px);
      -o-transform: translateY(248px);
      &:hover {
        transform: translateY(248px) scale(1.05);
        -webkit-transform: translateY(248px) scale(1.05);
        -moz-transform: translateY(248px) scale(1.05);
        -ms-transform: translateY(248px) scale(1.05);
        -o-transform: translateY(248px) scale(1.05);
      }
    }
    .box2,
    .box4 {
      transform: translateY(128px);
      -webkit-transform: translateY(128px);
      -moz-transform: translateY(128px);
      -ms-transform: translateY(128px);
      -o-transform: translateY(128px);
      &:hover {
        transform: translateY(128px) scale(1.05);
        -webkit-transform: translateY(128px) scale(1.05);
        -moz-transform: translateY(128px) scale(1.05);
        -ms-transform: translateY(128px) scale(1.05);
        -o-transform: translateY(128px) scale(1.05);
      }
    }
  }

  .content-box3 {
    margin: 32px 0;

    .box1 {
      transform: translateY(248px);
      -webkit-transform: translateY(248px);
      -moz-transform: translateY(248px);
      -ms-transform: translateY(248px);
      -o-transform: translateY(248px);
      &:hover {
        transform: translateY(248px) scale(1.05);
        -webkit-transform: translateY(248px) scale(1.05);
        -moz-transform: translateY(248px) scale(1.05);
        -ms-transform: translateY(248px) scale(1.05);
        -o-transform: translateY(248px) scale(1.05);
      }
    }
    .box2,
    .box4 {
      transform: translateY(128px);
      -webkit-transform: translateY(128px);
      -moz-transform: translateY(128px);
      -ms-transform: translateY(128px);
      -o-transform: translateY(128px);
      &:hover {
        transform: translateY(128px) scale(1.05);
        -webkit-transform: translateY(128px) scale(1.05);
        -moz-transform: translateY(128px) scale(1.05);
        -ms-transform: translateY(128px) scale(1.05);
        -o-transform: translateY(128px) scale(1.05);
      }
    }
  }
}

.content-swiper {
  width: 100%;
  // padding: 0 32px;
}

.team-group {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.team-member {
  width: 100%;
  position: relative;
  border-radius: 16px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  -o-border-radius: 16px;

  img {
    width: 100%;
  }

  .team-member-title {
    @include blured-bg;
    position: absolute;
    z-index: 1;
    bottom: 16px;
    right: 16px;
    left: 16px;
    width: auto;
    padding: 8px 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 16px;

    .name {
      font-family: "Kinetika-Regular", sans-serif;
      font-size: 18px;
      line-height: 22.5px;
      color: #fff;
    }

    .role {
      width: 100%;
      height: 40px;
      border-radius: 12px;
      background-color: #005235;
      color: white;
      font-family: "Kinetika-Regular", sans-serif;
      font-size: 18px;
      line-height: 22.5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 1440px) {
  .our-team-component {
    width: 100%;
    padding: 32px 32px 60px;
  }

  .team-group {
    padding: 32px 0 0;
    gap: 24px;
  }
}

@media (max-width: 768px) {
  .our-team-component {
    padding: 64px 16px;
    width: 100%;
  }

  .team-group {
    padding: 16px 0;
    gap: 16px;
  }

  .team-member {
    .team-member-title {
      left: 10px;
      right: 10px;
      bottom: 10px;
      padding: 4px 6px;
      gap: 6px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;

      .name {
        font-size: 12px;
        line-height: 16px;
      }

      .role {
        height: 24px;
        border-radius: 6px;
        font-size: 12px;
        line-height: 16px;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        -ms-border-radius: 6px;
        -o-border-radius: 6px;
      }
    }
  }
}
