@import "src/globall";

.Navbar {
  @include blured-bg;
  margin: 0 auto;
  width: 1440px;
  position: fixed;
  top: 24px;
  left: calc(50% - 720px);
  z-index: 1000;
  border-radius: 16px;
  transition: top 0.3s ease;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  ul {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    gap: 30px;
    padding-left: 0;

    li {
      list-style: none;
      white-space: nowrap;
    }
    a {
      font-family: "Kinetika-Regular", sans-serif;
      font-size: 18px;
      line-height: 22.5px;
      text-decoration: none;
      color: white;
    }
  }

  a {
    text-decoration: none;
  }

  button {
    @extend %globalButton;
    white-space: nowrap;
  }

  .logo-box {
    img {
      filter: brightness(0) invert(1);
    }

    &:hover img {
      filter: initial;
      -webkit-filter: initial;
    }
  }
}

.mobile-logo {
  display: none;
}

.scrolled-up {
  top: 24px;
}

.scrolled-down {
  top: -80px;
}

@media screen and (max-width: 1440px) {
  .Navbar {
    margin: 0 auto;
    width: 100%;
    top: 24px;
    left: 0;
  }

  .scrolled-up {
    top: 24px;
  }

  .scrolled-down {
    top: -80px;
  }
}

@media screen and (max-width: 1024px) {
  .Navbar {
    display: none;
  }
  .mobile-logo {
    position: absolute;
    width: min-content;
    z-index: 5;
    display: flex;
    padding: 24px 16px;
    img {
      filter: brightness(0) invert(1);
    }

    &:hover img {
      filter: initial;
      -webkit-filter: initial;
    }
  }
}

@media screen and (max-width: 768px) {
  .Navbar {
    display: none;
  }
}
