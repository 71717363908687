@import "src/globall";

.mobile-navbar {
  display: none;
}

.mobile-navbar-component {
  .content-box {
    display: flex;
    gap: 16px;
  }

  a {
    text-decoration: none;
    display: inline-block;
    width: 100%;
  }

  .header-button {
    @extend %globalButton;
    width: 100%;
    background: $accent;
    font-family: "Kinetika-Regular", sans-serif;
    font-weight: 600;
    padding: 5px 10px;
  }

  a {
    text-decoration: none;
  }

  .jobs-button {
    @extend %globalButton;
    width: min-content;
    background: $white;
    color: $primary;
    font-family: "Kinetika-Regular", sans-serif;
    font-weight: 600;
    padding: 5px 10px;
  }
  .icon-button {
    padding: 8px;
  }
}

.left-menu {
  ul {
    button {
      @extend %globalButton;
    }

    a {
      text-decoration: none;
    }
  }
}

@media (max-width: 1024px) {
  .mobile-navbar {
    display: block;
  }
  .mobile-navbar-component {
    position: fixed;
    display: block;
    bottom: 16px;
    width: 100%;
    padding-inline: 16px;
    z-index: 99;

    .mobile-navbar {
      @include blured-bg-modal;
      width: 100%;
      padding: 8px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;

      .content-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
      }
    }
  }
  .left-menu {
    @include blured-bg;
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    height: calc(100vh - 60px);
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.2s all linear;
    z-index: 500;

    ul {
      padding: 0 16px;
      width: 100%;
      list-style: none;
      display: flex;
      gap: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      li {
        font-family: "Kinetika-Regular", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        a {
          color: white;
          text-decoration: none;
        }
      }

      button {
        padding: 6px 30px;
      }
    }
  }

  .open {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
  }

  .close {
    top: 150%;
  }
}
