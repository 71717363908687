@import "src/globall";

.header-component {
  position: relative;
  height: 700px;
  overflow: hidden;
  width: 1440px;
  margin: 0 auto;

  .header-info-new {
    position: absolute;
    width: 100%;
    bottom: 10%;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 40px;
    z-index: 5;
  }

  .header-title {
    font-family: "Medium_Cyrillic", sans-serif;
    font-weight: 600;
    font-size: 40px;
    line-height: 57px;
    color: white;
    max-width: 1000px;
    width: 50%;
    text-transform: uppercase;
  }
  .header-text {
    max-width: 850px;
    font-family: "Kinetika-Regular", sans-serif;
    font-size: 18px;
    line-height: 30px;
    color: white;
    width: 450px;
  }

  .header-button {
    @extend %globalButton;
    width: max-content;
  }
}

@media (max-width: 1440px) {
  .header-component {
    width: 100%;
  }
}

@media (max-width: 1236px) {
  .header-component {
    width: 100%;
    overflow: visible;

    .header-title {
      max-width: 100%;
    }
    .header-text {
      max-width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .header-component {
    .header-info-new {
      z-index: 5;
      height: auto;
      position: relative;
      top: 55vh;
      padding: 0 32px;
      flex-direction: column;
      align-items: start;
    }
    .header-title {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  .header-component {
    width: 100%;
    overflow: visible;

    .header-info-new {
      padding: 0 16px;
      flex-direction: column;
      align-items: start;
      gap: 20px;
    }

    .header-title {
      max-width: 100%;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
    }
    .header-text {
      max-width: 100%;
      margin: 4px 0 24px 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    .header-button {
      font-size: 12px;
    }
  }
}
