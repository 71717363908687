@import "src/globall";

.our-portfolio-component {
  width: 1440px;
  margin: 0 auto;
  padding: 0 0 100px 0;

  .main-title {
    margin-bottom: 50px;
  }

  .cardContainer {
    height: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 50px;
    .card {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      top: -25%;
      height: 670px;
      border-radius: 40px;
      -webkit-border-radius: 40px;
      -moz-border-radius: 40px;
      -ms-border-radius: 40px;
      -o-border-radius: 40px;
      transform-origin: top;

      .descriptionCard {
        position: absolute;
        z-index: 11;
        display: flex;
        justify-content: space-between;
        align-items: start;
        gap: 10px;
        width: 100%;
        top: 5%;
        left: 0;
        padding-inline: 50px;

        .tags {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px;
          width: 270px;

          .item {
            box-sizing: border-box;
            width: fit-content;
            font-size: 16px;
            line-height: 20px;
            font-family: "Kinetika-Regular", sans-serif;
            color: white;
            flex-shrink: 0;
            flex-grow: 0;
            flex-basis: auto;

            &:hover {
              background: transparent !important;
              opacity: 0.7;
            }
          }
        }

        .textCard {
          display: flex;
          align-items: center;
          h3 {
            font-size: 16px;
            line-height: 20px;
            font-family: "Kinetika-SemiBold", sans-serif;
            color: white;
            margin-bottom: 0;
            text-transform: uppercase;
            white-space: nowrap;
          }

          img {
            height: 16px;
            width: 14px;
          }
        }
      }

      .titleCard {
        position: absolute;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-family: "Medium_Cyrillic", sans-serif;
        color: $white;

        h2 {
          font-size: 80px;
          font-weight: 500;
          text-align: center;
        }
      }

      .body {
        display: flex;
        height: 100%;
        gap: 50px;

        .imageContainer {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 25px;
          overflow: hidden;

          .inner {
            width: 100%;
            height: 100%;
          }
          .video-overlay {
            position: absolute;
            background: #00000066;
            width: 100%;
            height: 100%;
          }
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .our-portfolio-component {
    width: 100%;
    padding: 0 32px;

    .main-title {
      margin-bottom: 40px;
    }

    .cardContainer {
      .card {
        .titleCard {
          h2 {
            font-size: 48px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .our-portfolio-component {
    padding: 64px 16px 24px;
    width: 100%;

    .main-title {
      margin-bottom: 30px;
    }

    .cardContainer {
      height: 400px;
      .card {
        max-height: 380px;
        height: 100%;
        top: 0 !important;
        .descriptionCard {
          padding-inline: 20px;
          gap: 10px;

          .tags {
            gap: 10px;
            width: auto;

            .item {
              padding: 4px 8px;
              height: 24px;
              font-size: 12px;
              line-height: 16px;
              flex: auto;
              width: fit-content;
              flex-shrink: 0;
              flex-grow: 0;
              flex-basis: auto;
            }

            .item:last-child {
              flex: auto;
              flex-shrink: 0;
              flex-grow: 0;
              flex-basis: auto;
            }
          }

          .textCard {
            h3 {
              font-size: 12px;
              line-height: 16px;
            }

            img {
              height: 12px;
              width: 10px;
            }
          }
        }

        .titleCard {
          display: flex;
          justify-content: start;
          align-items: end;
          width: 100%;
          height: 100%;
          padding-bottom: 20px;
          padding-left: 20px;
          h2 {
            font-size: 20px;
          }
        }

        .body {
          .imageContainer {
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
        }
      }
    }
  }
}
