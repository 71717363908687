@import "src/globall";

.about-us-component {
  margin: 0 auto;
  width: 1440px;
  transform: translateY(-83px);
  position: relative;
  z-index: 3;

  .content-right {
    width: 1440px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .box {
      @include blured-bg;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 25%;
      height: 166px;
      color: white;

      &:hover {
        background: #00a0003d;
        backdrop-filter: blur(12px);
      }

      p {
        margin-bottom: 0;
      }

      .number {
        font-family: "Medium_Cyrillic", sans-serif;
        font-size: 64px;
        line-height: 77px;
      }

      p {
        font-family: "Kinetika-Regular", sans-serif;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .about-us-component {
    width: 100%;
    transform: translateY(-83px);
    -webkit-transform: translateY(-83px);
    -moz-transform: translateY(-83px);
    -ms-transform: translateY(-83px);
    -o-transform: translateY(-83px);
    padding: 16px;

    .content-right {
      width: 100%;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;

      .box {
        border-radius: 16px;
        width: 49%;
        padding: 24px 64px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .about-us-component {
    width: 100%;
    transform: translateY(0);
    padding-top: 64px;

    .content-right {
      width: 100%;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;

      .box {
        border-radius: 16px;
        width: 48%;
        padding: 24px 64px;
      }
    }
  }
}

@media (max-width: 768px) {
  .about-us-component {
    padding: 64px 16px;
    width: 100%;
    transform: translateY(0);

    .content-right {
      width: 100%;
      gap: 16px;
      flex-wrap: wrap;
      justify-content: center;

      .box {
        border-radius: 16px;
        width: 48%;
        padding: 24px 64px;

        p {
          margin-bottom: 0;
        }

        .number {
          font-size: 40px;
          line-height: 48px;
        }

        p {
          font-size: 14px;
          line-height: 32px;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .about-us-component {
    .content-right {
      width: 100%;
      flex-direction: column;

      .box {
        width: 100%;
        border-radius: 16px;
        padding: 24px 64px;

        .number {
          font-size: 40px;
          line-height: 48px;
        }

        p {
          font-size: 14px;
          line-height: 32px;
          text-align: center;
        }
      }
    }
  }
}
