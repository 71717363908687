@import "../../globall.scss";

.our-services-component {
  padding: 0 0 68px;

  .rfm-marquee-container,
  .rfm-initial-child-container,
  .rfm-marquee {
    gap: 20px;
    min-width: auto;
  }

  .main-title {
    margin: 0 auto;
    width: 1440px;
  }

  .carousel-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: hidden;
    width: 100%;
    margin-top: 64px;
  }

  .carousel-elements {
    @include button-global;
    padding: 0 40px;
    border: 1px solid #ffffff;
    flex: 0 0 auto;
    background-color: transparent;
    color: white;
    font-family: "Kinetika-Regular", sans-serif;
    // font-size: 24px;
    // line-height: 30px;
    transition: 0.2s linear;
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    &:hover {
      border: 1px solid #005235;
      background: #005235;
      color: white;
    }
  }
}

@media screen and (max-width: 1440px) {
  .our-services-component {
    .carousel-container {
      margin-top: 24px;
      gap: 18px;
    }

    .main-title {
      width: 100%;
      padding: 0 32px;
    }
  }
}

@media screen and (max-width: 768px) {
  .our-services-component {
    padding-bottom: 0;

    .rfm-marquee-container,
    .rfm-initial-child-container,
    .rfm-marquee {
      gap: 10px;
    }

    .main-title {
      width: 100%;
      padding: 0 16px;
    }

    .carousel-container {
      margin-top: 16px;
      gap: 16px;
    }

    .carousel-elements {
      padding: 16px 24px;
      border-radius: 12px;
    }
  }
}
