@import "src/globall";

.vacancies-page {
  //background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  //linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  position: relative;
  width: 1440px;
  margin: 0 auto;
  padding: 165px 0 120px 0;

  .main-title {
    margin-bottom: 64px;
  }

  .vacancies-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    gap: 24px;

    .box {
      height: 393px;
      padding: 40px;
      border-radius: 16px;
      border: 2px solid white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      color: white;
      text-decoration: none;
      text-align: center;
    }
    .box-header {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .direction {
      width: 150px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      border: 1px solid white;
      margin-bottom: 40px;
    }
    .job {
      font-family: "Kinetika-SemiBold", sans-serif;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 12px;
    }
    .experience {
      font-family: "Kinetika-Regular", sans-serif;
      font-size: 18px;
      line-height: 22.5px;
    }

    .box-footer {
      display: flex;
      justify-content: center;
      span {
        font-family: "Kinetika-Regular", sans-serif;
        line-height: 20px;
        text-transform: uppercase;
        margin: 0 4px;
      }
      img:last-child {
        transform: rotate(180deg);
      }
    }
  }
}

.single-vacancy {
  position: relative;
  width: 1440px;
  margin: 0 auto;
  padding: 165px 0 120px 0;
  color: white;

  .main-title {
    margin-bottom: 64px;
  }
  .mb-40 {
    margin-bottom: 40px !important;
  }
  .vacancy-detail {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    span:first-child {
      display: block;
      width: 220px;
      font-family: "Kinetika-Regular", sans-serif;
      font-size: 24px;
      line-height: 30px;
    }
    span:last-child {
      width: calc(100% - 220px);
      font-family: "Kinetika-Regular", sans-serif;
      font-size: 18px;
      line-height: 24px;
    }
  }

  .send-btn {
    display: flex;
    width: 100%;
    margin-top: 40px;
    justify-content: flex-end;
    a {
      text-decoration: none;
    }
    button {
      @extend %globalButton;
    }
  }
}

@media screen and (max-width: 1440px) {
  .vacancies-page {
    width: 100%;
    padding: 145px 32px 80px;

    .main-title {
      margin-bottom: 48px;
    }
  }

  .single-vacancy {
    width: 100%;
    padding: 145px 32px 80px;

    .main-title {
      margin-bottom: 48px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .vacancies-page {
    padding: 95px 32px 80px;

    .vacancies-box {
      grid-template-columns: 1fr 1fr;
    }
  }

  .single-vacancy {
    padding: 95px 32px 80px;
  }
}

@media (max-width: 765px) {
  .vacancies-page {
    width: 100%;
    padding: 80px 16px 0;

    .main-title {
      margin-bottom: 40px;
    }

    .vacancies-box {
      grid-template-columns: 1fr;

      .box {
        padding: 20px;
        height: 300px;
      }
    }
  }

  .single-vacancy {
    width: 100%;
    padding: 80px 16px 0;

    .main-title {
      margin-bottom: 40px;
    }
    .mb-40 {
      margin-bottom: 40px !important;
    }
    .vacancy-detail {
      margin-bottom: 16px;
      width: 100%;
      flex-direction: column;
      span:first-child {
        width: 100%;
        margin-bottom: 8px;
      }
      span:last-child {
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        opacity: 0.8;
      }
    }

    .send-btn {
      display: flex;
      width: 100%;
      margin-top: 40px;
      justify-content: flex-end;
      a {
        text-decoration: none;
      }
    }
  }
}
