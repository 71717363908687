@import "src/globall";

.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $layer-color;
  transform-origin: left;
  z-index: 9999;
}

.slide-in-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $primary;
  transform-origin: left;
  z-index: 9998;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $primary;
  transform-origin: left;
  z-index: 9997;
}

.slide-out-1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: $layer-color;
  transform-origin: left;
  z-index: 9996;
}
